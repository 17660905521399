#__next {
  .channel-body {
    margin-top: -54px;
  }

  .page-header-footer-v2 {
    .channel-body {
      margin-top: 0;
    }

    // 搜索框
    .channel-main .wrapper {
      z-index: 75;
      padding-top: 16px;
      margin-top: 0;

      .search-history {
        .title-action {
          height: auto;
          line-height: 1.3;

          h2 {
            color: #333333;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            font-weight: normal;
            height: 18px;
            letter-spacing: 0px;
            line-height: 18px;
            width: 98px;
          }
        }

      }


      .search-history-list {
        display: flex;
        //justify-content: space-between;

        li {
          margin: 0 0 0 16px;
          //width: auto;
          height: 76px;
          box-sizing: border-box;
          //flex-grow: 1;
          box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);

          &:first-child {
            margin-left: 0;
          }

          .item-top {
            .flight {
              color: #333333;
              font-family: PingFangSC-Semibold;
              font-size: 14px;
              font-weight: 600;
              height: 18px;
              letter-spacing: 0px;
              line-height: 18px;
              width: 28px;
              float: none;
            }
          }

          .item-main {
            margin-top: 8px;

            .date {
              color: #999999;
              font-family: PingFangSC-Regular;
              font-size: 12px;
              font-weight: normal;
              height: 16px;
              letter-spacing: 0px;
              line-height: 16px;
              width: 103px;
              float: none;
            }
          }
        }
      }
    }

    // 覆盖新头尾
    .date-components input {
      font-family: Helvetica Neue, Tahoma, Arial, PingFangSC-Regular, Hiragino Sans GB, Microsoft Yahei, sans-serif;
    }
  }
}
