$gray: #eef1f6;
$lightGray: #f8fafb;
$darkGray: #e2e5ea;
$white: #fff;
%loadingContainer {
	position: relative;
}
%lightLoadingContainer{
    position: relative;
    background-color: $lightGray;
}


@keyframes fake-flights-bling {
    0% {
        opacity: .4
    }

    50% {
        opacity: 1
    }

    100% {
        opacity: .4
    }
}
%loadingContent{
    position: absolute;
    background: $gray;
    animation: fake-flights-bling 2s ease infinite;

}
