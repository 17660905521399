@import 'common';
.loading-container{
    background-color: #EEF1F6;
    margin-top: -54px;
    .ad{
        @extend %loadingContainer;
        height: 260px;
        background-color: #DDE4ED;
    }
    .searchBox-container{
        background: $white;
        height: 230px;
        width: 1180px;
        padding: 0 0 24px 0px;
        margin: -48px auto 0;
        border-radius: 8px;
        @extend %loadingContainer;
        .tab{
            height: 48px;
            background-color: $darkGray;
            margin-bottom: 24px;
            border-radius: 8px 8px 0 8px;
            .left{
                width: 196px;
                height: 48px;
                @extend %loadingContent;
                background-color: $white;
                left: 0;
                border-radius: 8px 8px 0 8px;
                animation: none;
            }
        }
        .botton-submit{
            @extend %loadingContent;
            background-color: $darkGray;
            width: 176px;
            height: 56px;
            left: 50%;
            bottom: 0;
            margin: 0 0 -28px -88px;
            border-radius: 28px;
        }
    }

.fake-low-price-map {
    width: 1180px;
    position: relative;
    height: 532px;
    margin: 76px auto 30px;
    background-color: $white;
    border-radius: 8px;
    >div {
        position: absolute;
        background:$gray;
        animation: fake-flights-bling 2.5s ease infinite;
    }

    $firstLeft: 16px;
    $secondLeft: 176px;

    .r0c0 {
        width: 86px;
        height: 18px;
        top: 21px;
        left: $firstLeft;
    }

    .r1c0 {
        width: 304px;
        height: 64px;
        top: 60px;
        left: $firstLeft;
    }

    .r2c0, .r2c1, .r3c0, .r3c1, .r4c0, .r4c1, .r5c0, .r5c1 {
        width: 144px;
        height: 72px;
    }

    .r2c0, .r3c0, .r4c0, .r5c0{
        left: $firstLeft;
    }

    .r2c1, .r3c1, .r4c1, .r5c1{
        left: $secondLeft;
    }

    .r2c0, .r2c1 {
        top: 148px;
    }

    .r3c0, .r3c1 {
        top: 236px;
    }

    .r4c0, .r4c1 {
        top: 324px;
    }

    .r5c0, .r5c1 {
        top: 412px;
    }

    .map{
        width: 846px;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 0 8px 8px 0;
        background-color: $darkGray;
    }
}

}
